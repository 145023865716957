import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import globalStyles from './styles';
import CompanyDescription from "../components/AboutUs/content";
const AboutUsPage = (props) => {
    const {title, navigation} = props;
    return (
      <div>
        <span style={globalStyles.navigation}>{navigation}</span>

        <Paper style={globalStyles.paper}>
          <h3 style={globalStyles.title}>{title}</h3>
          <Divider />
          {props.children}
          <CompanyDescription />
          <div style={globalStyles.clear} />
        </Paper>
      </div>
    );
};

AboutUsPage.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.string,
};

export default AboutUsPage;
