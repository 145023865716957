import React from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import globalStyles from "../../pages/styles";


const CompanyDescription = () => {
  return (
    <CardContent >
      <h2>About Us</h2>
      <p>
        ABS is a well-established field engineering company based near
        Hollywood, Florida, with a proud history of serving the cable TV and
        telecom industry since 2017. Our team of highly skilled professionals
        brings a wealth of experience to every project, ensuring the seamless
        installation, maintenance, and optimization of cable TV and telecom
        networks.
      </p>
      <p>
        As a disabled veteran-owned company, we are committed to excellence in
        our field and dedicated to providing top-tier services to our clients
        across the Southeastern United States. Our mission is to deliver
        innovative solutions, exceptional quality, and unmatched reliability in
        the ever-evolving world of telecommunications.
      </p>
      <p>
        With a strong focus on customer satisfaction, ABS has earned a
        reputation for reliability, precision, and responsiveness. We take pride
        in our contributions to the growth and connectivity of communities
        throughout the Southeast, and we look forward to continuing to meet the
        evolving needs of the cable TV and telecom industry.
      </p>
      <p>
        ABS is your trusted partner for all your field engineering needs in the
        cable TV and telecom sector.
      </p>
    </CardContent>
  );
};

export default CompanyDescription;
