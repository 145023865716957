import React from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import logo from "../../images/Logo.png";
import sme1 from "../../images/sme_icons2.jpg";
import sme2 from "../../images/HowWeWork.jpg";
import sme3 from "../../images/WhereWeWork.jpg";
import sme4 from "../../images/WorkSmart_1.jpg";
import sme5 from "../../images/fixtheIssue.jpg";
import sme6 from "../../images/workAlone.jpg";
import sme7 from "../../images/compant_sme.jpg";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";


import { makeStyles } from "@material-ui/core/styles";
import globalStyles from "../../pages/styles";

const HomeLayout = (props) => {
  

  return (
    <div style={globalStyles.page}>
      <div>
        {" "}
        <div style={globalStyles.logoContainer}>
          <img src={logo} style={globalStyles.logo} alt="ABS Logo" />
          <Typography variant="h4" gutterBottom>
            Welcome to Our Website!
          </Typography>
        </div>
        <Typography variant="body1" paragraph>
          We're here to provide you with the best services.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Card style={globalStyles.card}>
              <CardMedia
                style={globalStyles.media}
                image={sme2}
                title="How we work"
              />
              <CardContent>
                <Typography variant="h6">How we Work</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card style={globalStyles.card}>
              <CardMedia
                style={globalStyles.media}
                image={sme3}
                title="Image 2"
              />
              <CardContent>
                <Typography variant="h6">Where We Work</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card style={globalStyles.card}>
              <CardMedia
                style={globalStyles.media}
                image={sme4}
                title="We Work Smarter"
              />
              <CardContent>
                <Typography variant="h6">We Work Smarter</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card style={globalStyles.card}>
              <CardMedia
                style={globalStyles.media}
                image={sme1}
                title="What we Do"
              />
              <CardContent>
                <Typography variant="h6">What we Do</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HomeLayout;
