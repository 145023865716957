const primary = {
  main: "#2699FB",
  light: "#7FC4FD",
  dark: "#F1F9FF",
  contrastText: "#F2CB57",
};

const styles = {
  root: {
    flexGrow: 1,
    marginTop: 2,
  },
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: primary,
    paddingBottom: 15,
    display: "block",
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
  },
  paper: {
    padding: 30,
  },
  clear: {
    clear: "both",
  },

  card: {
    maxWidth: 345,
  },
  media: {
    height: 340,
  },
  page: {
    /* Add your page-level styles here */
    padding: 2,
    backgroundColor: "#f0f0f0",
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items in the center
  },
  logo: {
    width: 100,
    marginRight: 2,
  },
};

export default styles;
