import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import CollectionsBookmark from "@material-ui/icons/CollectionsBookmark";
import CategoryIcon from "@material-ui/icons/Category";
import { withStyles } from '@material-ui/core/styles';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { NavLink } from 'react-router-dom';

const styles = theme => ({
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main
    },
  }
});
class Menu extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper>
        <MenuList>
          <NavLink to="/Home" style={{ textDecoration: "none" }}>
            <MenuItem className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary }}
                inset
                primary="Home"
              />
            </MenuItem>
          </NavLink>
          <NavLink to="/AboutUsPage" style={{ textDecoration: "none" }}>
            <MenuItem className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary }}
                inset
                primary="History"
              />
            </MenuItem>
          </NavLink>
          <NavLink to="/ProjectInfoList" style={{ textDecoration: "none" }}>
            <MenuItem className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <CollectionsBookmark />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary }}
                inset
                primary="Project Info"
              />
            </MenuItem>
          </NavLink>
        </MenuList>
      </Paper>
    );
  }
}


export default withStyles(styles)(Menu);

