import React from 'react';
import ManageProjectInfo from '../pages/ManageProjectInfo';
import ProjectInfoList from '../pages/ProjectInfoList';
import Home from "../pages/Home";
import AboutUsPage from '../pages/AboutUs';
import EditProjectInfo from '../pages/EditProjectInfo'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from '../App';


class Index extends React.Component {
  render() {
    return (
      <Router>
        <App>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Home" component={Home} />
            <Route exact path="/AboutUsPage" component={AboutUsPage} />
            <Route exact path="/ProjectInfoList" component={ProjectInfoList} />
            <Route exact path="/ProjectInfo" component={ManageProjectInfo} />
            <Route exact path="/ProjectInfo/:id" component={EditProjectInfo} />
          </Switch>
        </App>
      </Router>
    );
  }
}
export default Index;